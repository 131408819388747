import { Headset } from 'common/icons/Headset';
import { Lock } from 'common/icons/Lock';
import { PictureFrame } from 'common/icons/PictureFrame';
import Layout from 'common/layouts/Layout';
import {
  ButtonColor,
  PrimaryButton,
} from 'component-library/components/buttons/PrimaryButton';
import { Flex } from 'component-library/components/layout/Flex';
import { LinkWrapper } from 'component-library/components/links/LinkWrapper';
import { Navigation } from 'component-library/components/navigation/Navigation';
import { Body } from 'component-library/components/typography/Body';
import { Heading } from 'component-library/components/typography/Heading';
import { PageWrapper } from 'component-library/components/wrappers/PageWrapper';
import { colors } from 'component-library/styles/colors';
import { CalendlyInlineWidget } from 'components/common/CalendlyInlineWidget';
import { AdvisorBanner } from 'components/pages/talk-to-an-advisor/AdvisorBanner';
import { SEO } from 'components/SEO';
import { Footer } from 'page-components/shared/footer';
import { WelcomeWrapper } from 'page-components/welcome/welcomeUtils';
import React, { FC } from 'react';

interface TalkToAnAdvisorProps {}

const TalkToAnAdvisor: FC<TalkToAnAdvisorProps> = () => (
  <Layout>
    <Navigation simpleNav />
    <PageWrapper color={colors.greenLight}>
      <WelcomeWrapper>
        <Heading variant={4} marginBottom={32}>
          Ahead of Your Call
        </Heading>
        <AdvisorBanner />
        <Body medium variant={3} marginBottom={12}>
          Your Upcoming Call with our Fiduciary Advisor
        </Body>
        <Body variant={3}>
          In the next day, one of our certified retirement advisors will get in
          touch to help set you on the path to a stress-free retirement.
        </Body>
        <Body variant={3} marginBottom={32}>
          You won&apos;t need to prepare anything for our first call. We&apos;ll
          have a short conversation to discuss your current financial situation
          and your retirement goals.
        </Body>
        <Body medium variant={3} marginBottom={12}>
          Questions We&apos;ll Answer:
        </Body>
        <Body variant={3} marginBottom={32}>
          <ul style={{ listStyleType: 'circle', paddingInlineStart: '20px' }}>
            <li>How can I make my savings last?</li>
            <li>Will my income be enough?</li>
            <li>When can I retire?</li>
            <li>What should I do with my 401(k)?</li>
          </ul>
        </Body>
        <Body medium variant={3} marginBottom={12}>
          What We&apos;ll Accomplish
        </Body>
        <Body variant={3}>
          <span style={{ paddingRight: '10px' }}>
            <Headset style={{ display: 'inline-block' }} />
          </span>
          Answer your most pressing retirement questions
        </Body>
        <Body variant={3}>
          <span style={{ paddingRight: '10px' }}>
            <PictureFrame style={{ display: 'inline-block' }} />
          </span>
          Build a complete picture of your retirement
        </Body>
        <Body variant={3} marginBottom={32}>
          <span style={{ paddingRight: '10px' }}>
            <Lock style={{ display: 'inline-block' }} />
          </span>
          Pinpoint how to make your savings last
        </Body>
        <Body variant={3} marginBottom={12}>
          As a fiduciary investment advisor, we know it&apos;s important to know
          who you&apos;re working with. To learn more about Retirable and our
          investment services, you can download our brochure:
        </Body>
        <Flex justifyContent="center" marginBottom={32}>
          <LinkWrapper to="/brochure">
            <PrimaryButton buttonColor={ButtonColor.Peach}>
              Download Brochure
            </PrimaryButton>
          </LinkWrapper>
        </Flex>
        <Body medium marginBottom={12} variant={3}>
          Schedule a time to call instead
        </Body>
        <Body variant={3} marginBottom={12}>
          If you&apos;d prefer to schedule a time for our first call, please go
          ahead and pick one of the time slots we&apos;ve reserved for you,
          before we open it up for others waiting.
        </Body>
        <CalendlyInlineWidget />
      </WelcomeWrapper>
      <Footer />
    </PageWrapper>
  </Layout>
);

export default TalkToAnAdvisor;

export const Head = () => (
  <SEO
    title="Talk to an Advisor"
    description="Talk to an advisor about your unique goals and objectives"
  />
);
