import { Flex } from 'component-library/components/layout/Flex';
import React, { FC } from 'react';
import { InlineWidget, useCalendlyEventListener } from 'react-calendly';
import { track } from 'utils/analytics';

interface CalendlyInlineWidgetProps {
  url?: string;
}

const DEFAULT_CALENDLY_URL =
  'https://calendly.com/retirable/initial-consultation';

export const CalendlyInlineWidget: FC<CalendlyInlineWidgetProps> = ({
  url,
}) => {
  useCalendlyEventListener({
    onEventScheduled: () => {
      track('Calendly Call Scheduled');
    },
  });

  return (
    <Flex column>
      <InlineWidget url={url || DEFAULT_CALENDLY_URL} />
    </Flex>
  );
};
