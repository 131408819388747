import React, { FC, SVGProps } from 'react';

export const Headset: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="20" height="20" viewBox="0 0 16 16" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.85 6.34C14.18 2.72 11.37 0 8 0C4.63 0 1.82 2.72 1.15 6.34C0.47 6.9 0 8.1 0 9.5C0 11.43 0.9 13 2 13C2 14.1 2.9 15 4 15H6C6 15.55 6.45 16 7 16H9C9.55 16 10 15.55 10 15V14C10 13.45 9.55 13 9 13H7C6.45 13 6 13.45 6 14H4C3.45 14 3 13.55 3 13C3.55 13 4 12.55 4 12V7C4 6.55 3.7 6.19 3.29 6.06C3.97 3.7 5.81 2 8 2C10.19 2 12.03 3.7 12.71 6.06C12.3 6.19 12 6.55 12 7V12C12 12.55 12.45 13 13 13H14C15.1 13 16 11.43 16 9.5C16 8.1 15.53 6.9 14.85 6.34Z"
      fill="#333333"
    />
  </svg>
);
