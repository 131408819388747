import { Flex } from 'component-library/components/layout/Flex';
import { Subheading } from 'component-library/components/typography/Subheading';
import { colors } from 'component-library/styles/colors';
import { StaticImage } from 'gatsby-plugin-image';
import React, { FC } from 'react';
import styled from 'styled-components';

const AdvisorImage = styled.div`
  border-radius: 50%;
  overflow: hidden;
`;

interface AdvisorBannerProps {}

export const AdvisorBanner: FC<AdvisorBannerProps> = () => (
  <Flex marginBottom={32} alignItems="center">
    <AdvisorImage>
      <StaticImage
        src="../images/tyler-advisor.jpeg"
        alt="advisor"
        width={80}
        height={80}
      />
    </AdvisorImage>
    <Subheading marginLeft={12} variant={2} color={colors.forestGreen}>
      Meet Tyler, your Certified Financial Planner™
    </Subheading>
  </Flex>
);
